.FormFileBlock-warp{
    display: flex;
    justify-content: center;
    width: 100%;
}
.FormFileBlock-inner{
    max-width: 1096px;
    width: 100%;
    margin-bottom: 120px;
}
.FormFileBlock-Textinputs{
    display: flex;
    width: 100%;
    justify-content: center;
}
.FormFileBlock-Textinputs-inner{
max-width: 624px;
width: 100%;
display: flex;

justify-content: center;
}
.FormFileBlock-Textinputs-inner input{
    width: 100%;
    border: 0px;
    background-color: #f2f3f4;
    height: 48px;
    padding: 16px;
}
.FormFileBlock-Textinputs-inner div:last-child{
    margin-left: 32px;
}
#ApplyButton{
    padding: 16px 32px;
    background: #005094;
    border-radius: 24px;
    cursor: pointer;
    color: white;
    width: 210px;
    border: 0px ;
    margin-top: -15px;
}
.FormFileBlock-Textinputs-inner div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: space-between;
}
.FormFileBlock-Textinputs-inner div label{
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
}
.FormFileBlock-Textinputs-inner div label span{
    margin-bottom: 8px;
    font-weight: 600;
    color: #001020;
}
.FormFileBlock-Fileupload{
    margin-top: 64px;
    width:100%;
    display: flex;
    justify-content: center;
}
.FormFileBlock-Fileupload-inner{
    max-width: 624px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#FormFileBlock-Document{
    display: none;
}
#FormFileBlock-Document-label{
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 52px;
    background: black;
    border-radius: 24px;
    cursor: pointer;
    color: white;
    width: 215px;
    border: 0px ;
    position: relative;
}
#FormFileBlock-uploadpdf-text{
    position: absolute;
}
#FormFileBlock-Document-label svg{
    position: absolute;
    top: 16px;
    right:24px;
}
#docsError{
    color: #ff9494;
    width: 100%;
    margin-top: 16px;
    text-align: center;
}