.Header-top{
    display: grid;
    z-index: 10;
    grid-template-columns: 60% 40%;
}
.Header-menu1{
    z-index: 301;
}
.Header-menu1 ul{
    display: flex;
    align-items: center;
    font-size: 15px;
    color:white;
}
.Header-menu1 ul li a{
    color:white;
    text-decoration: none;
}

.Header-menu1 ul li{
    padding-left: 24px;
}
.Header-menu1 ul li:nth-child(2){
    padding-left: 40px;
}
.Header-menu1 ul li:first-child{
    padding-left:1px;
}
.Header-menu2 ul{
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 115px;
    justify-content: right;
    color:white;
    max-width: 1000px;
}
.Header-menu2 ul li{
    padding-right: 16px;
}
.Header-wrapper{
    position:relative;
}
.header-banner{
    width: 100%;
    margin-top: -118px;
    height: 720px;
    
}
.header-image{
    width: 102%;
    height: 720px;    
    background-size: cover;
    position: relative;
    z-index: -2;
}
.header-overlay{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    
}
.header-texts{
    width: 100%;
    max-width: 1096px;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50% , -50%);
    display: grid;
    grid-template-columns: 65% 35%;
    margin-top: 67px;
    z-index: 1;
}
.Header-wrapper .sticky{
    position: fixed !important;
    width: 100%;
    max-width: unset;
    top: 0px;
    z-index: 9999;
    left:0px

}
.header-text-left div{
   
}
.header-text-left div h2{
    font-size: 72px;
    letter-spacing: -3.6px;
    color: white;
    font-weight: 600;
}
.header-text-left div button{
    background-color: #005094;
    border-radius: 24px;
    margin-top: 32px;
    color:white;
    font-size: 13px;
    width: 166px;
    font-weight: 300;
    padding: 16px 32px 16px 32px;
    border: 0px;
    cursor: pointer;
}
.header-text-left div h4,.header-text-left div p{
    margin-top: 24px;
    font-size: 16px;
    color:white;
    letter-spacing: -0.32px;
    font-weight: 300;   
    line-height: 24px;
}

#brand-line{
    color: white;
    opacity: 1;
    font-size: 12px;
    letter-spacing: 0.1em;
    margin-top: 80px;
    height: 16px;
}
.header-selection{
    width: 100%;

    position: absolute;
 
}
#languagemenu{
    position: relative;
    z-index: 2;
}
.smalllanguagemenu{
    position: absolute;
    top: 35px;
    left :-60px;
    background-color: white;
    width: 180px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 0px;
}

.smalllanguagemenu button img{
    margin-left: 5px;
    margin-top: 9px;
}
.smalllanguagemenu .normal{
    background-color: white;
}
#changeregion{
    margin-top: 20px;
    font-weight: 500;
    background-color: white;
    
    margin-bottom: 0px;
}
#changeregion img{
    margin-left: 5px
}
.header-selection-menu a{
    color: black; 
    text-decoration: none;
}
.header-selection-menu{
    float: right;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    max-width: 600px;
    margin-top: -80px;
    background-color: white;
    height: 80px;
    z-index: 40;
    
}
.header-selection-menu div{
    display: flex;
    justify-content: center;
    position: relative;
}
.header-selection-menu div div{
    display: block;
    justify-content: center;
    position: relative;
}
.header-selection-menu div a{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
}
.header-selection-menu div a svg{
    margin-left: 16px;
}
.header-selection-menu p{
    margin-top: 20px;
    color: #333;
    opacity: 0.8;
}
#languagemenu img{
    cursor: pointer;
}
.smalllanguagemenu button{
    position: relative;
    cursor: pointer;
    padding: 15px 10px 15px 10px;
}
.smalllanguagemenu button img{
    width: 20px;
    position: absolute;
    left: -2px;
    top: 3px;
}
.header-text-wrap{
    width: 100%;
    max-width: 2400px;
}
.Header-wrapper .sticky{
    height: 72px;
  
    width: 100%;
}

.sticky #Header-top {
    max-width: 1320px;
    margin: 0 auto;
    width: 100%;
}
.HeaderSlim-wrapper{
    height: 72px;

}
.Header-wrapper{
   width: 100%;

}
.lang-dev-wrap{
    display:grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
    gap: 20px;
}
.activelang{
    border:1px solid #005094;
    background-color: #005094;
    color:white;
}
#searchmenu{
    display: flex;
    margin-top: -5px ;
    
    z-index: 300;
}
#searchmenu img{
    cursor: pointer;
}
.suggestrender{
 position: absolute;
 left: -100px;
 z-index: 100;
}
.suggestmainpage{
    position: absolute; 
    margin-top: 70px;
    width: 100%;
    left: 0%;  
    margin-top: -27px;
    background-color: white;
    z-index: 200;
}
.langtogglemenu button img{
    height: 20px;
}
.Header-menu2 ul li:first-child a{
    text-decoration: none;
    color:white;
}
.SuggestProduct a{
    display:flex;
    text-decoration: none;
    font-size: 12px;
    color:black;
    align-items:center;
}
.SuggestProduct{
    width:350px;
    background-color: white;
}

.HeaderSlim .Header-menu1 ul li a
,.HeaderSlim .Header-menu2 ul li:first-child a{
  color: #001020
}
#Qrailings{
    display: flex;
    height: 100%;
    align-items: center;
}

#Qrailingshop,#Qrailingsconfigurator{
    display: flex;
    height: 100%;
    align-items: center;
}
.Header-menu2 ul li:last-child{
    padding-right: 0px !important;
}
#Qrailings:hover #Qrailingssubmenu{
    display: flex;
    width: 100%;
    animation: SlideHeaderAnimation2 300ms ease-in-out;

}
#Qrailingservice:hover #Qrailingssubmenu2{
    display: flex;
    width: 100%;
    animation: SlideHeaderAnimation2 300ms ease-in-out;
}
#Qrailingsconfigurator:hover #Qrailingssubmenu4{
    display: flex;
    width: 100%;
    animation: SlideHeaderAnimation2 300ms ease-in-out;
}
#Qrailingshop:hover #QrailingssubmenuShop{
    display: flex;
    width: 100%;
    animation: SlideHeaderAnimation2 300ms ease-in-out;
}
#Qrailingssubmenu, #Qrailingssubmenu2, #Qrailingssubmenu3, #Qrailingssubmenu4,#QrailingssubmenuShop{
    position:absolute;
    display:none;
    left: 0px;

}
#Qrailingssubmenu ul , #Qrailingssubmenu2 ul, #Qrailingssubmenu3 ul, #Qrailingssubmenu4 ul,#QrailingssubmenuShop ul{
    display: flex;

    flex-direction: column;
    background-color: #F2F3F4;
}
#Qrailingssubmenu ul li a, #Qrailingssubmenu2 ul li a, #Qrailingssubmenu3 ul li a{
    color:black
}

#Qrailingssubmenu ul li,#Qrailingssubmenu2 ul li, #Qrailingssubmenu3 ul li, #Qrailingssubmenu4 ul li{
    text-align: start;
    width:300px;

    padding-left: 0px;
}
#Qrailingservice{
    display: flex;
    height: 100%;
    align-items: center;
}
.systemheader-image{
    height: 720px;
    background-image: url("https://q-railing.s3.eu-central-1.amazonaws.com/images/ealu.jpg");
}
.systemheader-text{
    margin-top: 250px ;
}
#responsivemenuitems{
    display:flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    background-color: white;
    color: black;
    z-index: 9000;
    margin-top: 107px;
    overflow-y: scroll;
    height: 100%;
   
}

.HeaderMainpage-CardWrapInner{
    text-align: left;
    width: 100%;
    padding-left: 24px;

}
::-webkit-scrollbar {
    width: initial;
    width: 4px;
   
}
::-webkit-scrollbar-track {
    background: initial;
    
}
::-webkit-scrollbar-thumb {
    background: initial;
   
}
::-webkit-scrollbar-thumb {
    background-color: #005094;
}

/* Set the color for the track (the background) */
::-webkit-scrollbar-track {
    background-color: transparent;
  
}
#responsivemenuitems .HeaderMainpage-Submenu-Card img{
    margin-right: 16px ;
}
.HeaderMainpage-whatsNewLink{
    color: #667079 !important;
    font-size: 16px;
    margin-top: 8px;
    text-decoration: underline !important;
}
.HeaderMainpage-Submenu-Card h3{
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
    text-align: left;
}
.HeaderMainpage-Submenuul li{
    max-height: 48px;
}
.ResponsiveMenu-BottomLinks-1{
    position: absolute;
    bottom: 116px;
    background-color: white;
    left: 0px;
    padding-top:16px;
    padding-bottom: 16px;
    padding-left: 16px;
    margin-right: 16px;
    width: calc(100% - 16px) !important;
}
.HeaderMainpage-Submenu li{

}

.Qrailingssubmenu-innerService{
    max-width: 1096px !important;
    padding-bottom: 72px;
    height: 100%;
}
.ResponsiveMenu-BottomLinks-2{
    position: absolute;
    bottom: 53px;
    background-color: white;
    left: 0px;
    padding-top:16px;
    padding-bottom: 16px;
    width: calc(100% - 16px) !important;
    padding-left: 16px;
}
#responsivemenuitems li{
  
  
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #CCCFD2;
   
}
#responsivemenuitems li a{
    width: 100%;
  
    font-size: 16px;
    align-items: center;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    display: flex;
    justify-content: space-between;

}
.ResponsiveMenuHeaderLink{
    height: 80px;
}

#responsivemenuitems .ResponsiveMenuHeaderLink:first-child svg{
    margin-right: 16px;
}
#resp-menu li button:hover{
    transform: scale(1.1);
    opacity: 0.9;
    cursor: pointer;
    transition: 0.3s;
}
.HeaderMainpage-SwoopMenu{
    min-height: 528px !important;
    align-items: flex-start  !important;
    flex-direction: column;
    justify-content: flex-start !important;
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;
    overflow-y: scroll;
    background-color: #f2f3f4;
    padding-bottom: 32px;
}
#SupportResponsive-Header-line2{
    padding-top:40px;
    border-top: 1px solid #cccfd2;
    margin-top: 24px;
}
.HeaderMainpage-whatsNewLink{
    height: unset !important;
    margin-top: 16px;
    text-decoration: underline;
}
.HeaderMainpage-Submenu-Cards{
    background-color: #f2f3f4;
  
    width: 100%;
    margin-top: 48px;
}
.HeaderMainpage-Submenu-Card2{
    margin-top: 24px;
}
.HeaderMainpage-Submenu-Card{
    display: flex;
    width: 100%;
   
    background-color: white;
    justify-content: space-between;
}

.HeaderMainpage-Submenu-Card-Service{

}
@media (min-width:1024px) {
    .HeaderMainpage-Submenu-Card-Service img{
        width: 142px ;
    }
}


.HeaderMainpage-CardWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.HeaderMainpage-SwoopMenu div h4{
    color: #667079;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
}
#HeaderMainpage-SwoopMenu-close{
    margin-top: 24px;
    margin-bottom: 32px;
}
.HeaderMainpage-Submenu li{
    border: none !important;
    box-shadow: none !important;

}
.HeaderMainpage-Submenu li a{
    margin-left: 0px !important;
    font-size: 32px !important;
    letter-spacing: -0.96px;
    line-height: 40px;
    color: #667079 !important;
    font-weight: 600;
}
.HeaderMainpage-Submenu li a:hover{
    color: #001020 !important;
}
#responsivemenuitems li a:first-child{
    margin-left: 16px;
}
#responsivemenuitems li button{
    background-color: transparent;
    border: none;
    margin-right: 16px;
}
.HeaderMainpage-Submenu{
    display: flex;
    flex-direction: column;
    width: 100%;

}
#responsivemenuitems li a img{
    width: 32px;
    height: 32px;
    opacity: 0.8;
    margin-right: 16px;
}
#HeaderSystemDetail-buynowbutton{
    display: none;
    margin-top: 24px;
    padding: 16px 24px;
    background-color: #005094;
    color: white;

    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    max-width:104px;
    border-radius: 24px;

}
.systemheader-text h2{
    font-size: 72px;
    line-height: 80px;
    font-weight: 200 !important;

}
.systemheader-text{
    color: #ffffff;
}
.systemheader-text h2 h3{
    font-weight: 600 !important;
    font-size: 72px ;
    margin-bottom: 24px;
}
.systemheader-text h4{
    font-size: 16px !important;
    max-width: 536px;
    font-weight: 300;
    color: white;
    letter-spacing: -0.32px;
}
.systemheader-text-wrap{
top: 36%;
}
.SystemsSpecials-wrap{
    width: 100%;
    display: flex;
    background-color: #F2F3F4;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
}
.SystemSpecials ul li{
    list-style: none;
    width: 100px;
    display: flex;
    align-items: center;
}
.SystemSpecials{
    display: flex;
    justify-content: center;
    max-width: 1096px;
    width: 100%;
}
.SystemSpecials ul:last-child{
display: flex;
justify-content: flex-end;
}
.SystemSpecials ul:last-child button{
    background-color: #005094;
    color: white;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
    box-shadow: none;
    border: none;
    cursor: pointer;
}
.SystemSpecials ul{
display: flex;
text-align: center;
justify-content: space-between;
width: 50%;

}
.SystemSpecials ul li button{
 border: none;

 cursor: pointer;
 text-align: left;
}
.SystemSpecials ul li:nth-child(4){
    width: 55px;
}
.SystemSpecials ul li:nth-child(1){
    width: 80px;
}
.SystemSpecials ul li:nth-child(5){
    width: 75px;
}
.Stickcenter {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
    left: 50%;
    z-index: 10000;

}
.sticky2 {
    position: fixed;
    top: 50px;
    z-index: 999;
    background-color: #F2F3F4;
    width: 100%;
    border-bottom: 1px solid #eee !important;
}
.subheader-headernew{
    max-width: 560px;
  
}
.fully{
    position: fixed !important;
    z-index: 3000;
    top: 0px;
    width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111;
}
.fully div{

    width: 200px;
  
   color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.header-top-wrap{
    

}
#Qrailingssubmenu .Qrailingssubmenu-wrap, #Qrailingssubmenu2 .Qrailingssubmenu-wrap
, #Qrailingssubmenu3 .Qrailingssubmenu3-wrap,.ShopmenuHeaderWrap, #Qrailingssubmenu4 .Qrailingssubmenu4-wrap{
    height: unset;
    position:absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #F2F3F4;
    z-index: 1000;   
    height: 576px;
    color: #001020;
    margin-top: 35px;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
   border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    flex-direction: column;
    
}
.ShopmenuHeaderWrap{
    margin-top: 27px;
}
#Qrailingssubmenu ul, #Qrailingssubmenu2 ul{
    height: unset;
}
.Qrailingssubmenu-inner .img-fake,.Qrailingssubmenu-inner .img-fake{
    max-height: 448px;
    height: 448px;
    background-size: cover;
    background-image: url("https://q-railing.s3.eu-central-1.amazonaws.com/images/railingg1.jpg");
}
.Qrailingssubmenu-innerService-wrap{
    
}

.Qrailingssubmenu-inner{
    width: 100%;
    max-width: 1440px;
    display: grid;
    padding-top: 72px;
    
    margin: 0 auto;
    grid-template-columns: 378px 1fr 1fr;
}
.Qrailingssubmenu-innerService{
    width: 100%;
    max-width: 1440px;
    display: grid;
    padding-top: 72px;
    
    margin: 0 auto;
    grid-template-columns: 312px 440px 1fr;
}
.Qrailingssubmenu3-inner{
    height: 420px !important;
}
.header-submenu-headline{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.Qrailingssubmenu-inner h2{
   width: 200px;
 
   text-align: left;
}
.HeaderMainpage-Submenuul li a{
    color: #CCCFD2 !important;
}
.HeaderMainpage-Submenuul li a:hover{
    color:#001020 !important
    ;font-weight: 600;
}
.Qrailingssubmenu-innerService ul:first-child{
    border-right: 1px solid #CCCFD2;
}
.Qrailingssubmenu-innerService ul:first-child li:last-child{

}
.Qrailingssubmenu-innerShop ul:first-child li:last-child{
    margin-top: 0px !important;
}
.Qrailingssubmenu-innerService .HeaderMainpage-Submenu-Cards{
    padding-left: 24px;
    border-left: 1px solid #CCCFD2;
}
.Submenu-buttons-Shop{
    position: absolute;
    bottom: 0px;
}
.Submenu-buttons-Shop-inner{

}
#Qrailingssubmenu ul li,#Qrailingssubmenu2 ul li,#Qrailingssubmenu3 ul li,#Qrailingssubmenu4 ul li,#QrailingssubmenuShop ul li{
    width: 100%;
    display: flex;
    padding-left: 24px;
    justify-content: flex-start;
    position:relative;
    z-index: 1000;

}

#Qrailingssubmenu ul li:first-child a,#Qrailingssubmenu2 ul li:first-child a,
#Qrailingssubmenu3 ul li:first-child a,#Qrailingssubmenu4 ul li:first-child a{
    color: #001020;
}
#Qrailingssubmenu ul li a,#Qrailingssubmenu2 ul li a,#Qrailingssubmenu3 ul li a,#Qrailingssubmenu4 ul li a,#QrailingssubmenuShop ul li a{
    font-size: 24px; 
    height: 100%;
    color: #CCCFD2;
}

#Qrailingssubmenu ul li:first-child,#Qrailingssubmenu2 ul li:first-child,#Qrailingssubmenu4 ul li:first-child,#Qrailingssubmenu3 ul li:first-child,#QrailingssubmenuShop ul li:first-child{
    margin-top: 0px;
}


.Submenu-buttons{
    display: flex;
    width: 100%;
    height: 80px;
    background-color: #F2F3F4;
}
.Submenu-buttons-Shop-inner{
    grid-template-columns: unset !important;
    max-width: unset !important;
}
.Submenu-buttons-inner div a{
    color: #001020 !important;
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
}
.Submenu-buttons-inner div a:first-child{
    margin-left: 32px;
    margin-right: 40px;
}
.Submenu-buttons-inner div a img, .Submenu-buttons-inner div a svg{
    margin-left: 16px;
    
}
.Submenu-buttons-inner div{
    display: flex;
  
}
.Submenu-link-side{
    background-color: white;
}


.suggestmainpage{
    animation: SlideHeaderAnimation2 300ms ease-in-out
}
.Submenu-buttons-inner{

 width: 100%;
 display: grid;
 grid-template-columns: 172px 1fr;
 margin: 0 auto;
 text-align: left;
}

#responsive-logo{
    width: 48px;
    height: 48px;

}
.Header-menu2{
    max-height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;

}
.Header-menu2 a{
    font-size: 15px !important;
}

.Qrailingssubmenu-inner .img-fake,.Qrailingssubmenu-inner .img-fake{
    max-height: 448px;
    height: 448px;
    background-size: cover;
    background-image: url("https://q-railing.s3.eu-central-1.amazonaws.com/images/railingg1.jpg");
}
.SystemsSpecials-wrap{
    display: none;
}
#registerHeaderMenu{
    margin-top: -5px ;
    z-index: 300;
}
.HeaderMainpage-Service-headline{
    color: #667079;
    width: 100%;
    padding-bottom: 24px;


}
.HeaderMainpage-Submenuul{
    position: relative;
}
#HeaderMainpage-ServiceHeadline2{
    padding-left: 24px;
}
.Qrailingssubmenu-innerShop{
    height: 100%;
}
.Qrailingssubmenu-innerService .HeaderMainpage-Submenu-Cards{
    margin-top: 0px;
}
#mainmenu li{
    height: 100% !important;
 
}
#Qrailingshop,#Qrailingsconfigurator,#QrailingInspiration,#Qrailings,#Qrailingservice{
    padding-bottom: 26px !important;
    margin-top: 26px;
}
@keyframes SlideHeaderAnimation {
    0%{
        opacity: 0;
        -webkit-transform: translateY(-60px);
        -ms-transform: translateY(-60px);
        transform: translateY(-60px);
    }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes SlideHeaderAnimation2 {
    0%{
        opacity: 0;
      
    }
    100%{
        opacity: 1;
      
    }
}

