@media (min-width:1620px) {
    .header-image {
        background-position-y: 50% !important
    }
    .Submenu-buttons-Shop {
    
    left: calc(50% - 548px)
    }
    
    
    }
    
    @media (min-width:1024px) {
    .header-submenu-headline h2{
        font-size: 14px !important;
        line-height: 16px;
        color: #667079 !important;
        letter-spacing: 1.2px;
    
    }

    .businesscustomer{
        min-height: 550px;
    }
    .contactmenu textarea{
        padding-bottom: 78px;
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For IE/Edge */
    }
    .contactmenu textarea::-webkit-scrollbar{
        display: none;
    }

    .Selects-configu{
        min-height: 820px;
    }
    .Qrailingssubmenu-innerService li {
       max-height: 40px !important;
    }
    .HeaderMainpage-Submenuul li a{
        font-size: 32px !important;
        line-height: 32px;
    }
    .Qrailingssubmenu-innerShop ul:first-child li,.Qrailingssubmenu-innerService ul:first-child li{
        padding-left: 0px !important;
    }
    .Qrailingssubmenu-innerService li a{
        font-size: 24px !important;
    }
    }
    @media (max-width:1620px) {
        .header-texts{
            display:block;
        }
        .header-selection-menu {
           width: 45%;
        }
        #modelcanvas2{
        }
    }
    
    
    @media (max-width:1320px) {
    .configuratorsteps {
    
        justify-content: flex-start !important;
        margin-left: 24px;
    
    }
    .rotationbox{
        justify-content: flex-start !important;
       
    }
    #modelcanvas2{
        margin-left: -64px;
    }
    .systemgrid-head{
        flex-direction: column;
        padding-left: 16px;
        padding-right: 16px;
    }
    .systemgrid-grid{
        padding-left: 16px;
        padding-right: 16px;
    }
    .systemgrid-head .projectblock-filter{
        margin-top: 24px;
    }
    
    }
    
    
    @media (max-width:1024px) {
        .systemheader-text{
            padding-left: 16px;
            padding-right: 16px;
        }
        .faq-wrapper{
            padding-top: 64px !important;
            padding-bottom: 64px !important;
            padding-left: 16px;
            padding-right: 16px;
        }
        .NeedHelp-Head{
        margin-right: 16px;
        margin-left: 16px;
        }
        .paginationproducts{
            position: absolute;
            bottom: -50px;
        }
        .Searchresults-search{
            position: fixed !important;
            top: -2px !important;
            padding-bottom: 10px !important;
            background-color: white;
        }
        .configuratorsteps-btnrow{
            max-width: 541px;
        }
        .projectblock-col2{
            grid-area: unset !important;
            height: 100%;
            width: 100%;
        }
        .header-banner{
            margin-bottom: 60px !important;
            margin-top: -49px !important;
        }
        .header-image{
            height: 652px !important;
        }
        .SiteScrollMenu-wrap{
           
            padding-left: 16px;
            padding-right: 16px;
            position: relative;
            z-index: 3;
        }
        .projectblock-row1{
            display:  block !important;
        }
        .projectblock-row1 img,  .projectblock-row2 img{
          
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
        }
        .projectblock-col1{
            row-gap: 0px !important;
        }
        
        .projectblock-row2{
            display: flex !important;
            flex-direction: column;
            gap: 0px !important;
            width: 100%;
            margin-top: 0px !important;
        }
        .projectblock-page{
            margin-top: 0px !important;
            align-items: center;
        }
        .HeaderMainpage-Submenu-Cards2{
            width: calc(100% - 16px);
        }
        .HeaderMainpage-Submenu div h4{
            margin-bottom: 16px;
        }
        .Header-top div ul{
            height: unset !important;
        }
        .HeaderMainpage-Submenu li:first-child{
            margin-top: 16px !important;
        }
        #modelcanvas2{
            margin-left: 0px;
        }
        .Header-menu2{
            display: none !important;
        }
        .configurator-wrap {
        padding-right: 16px;
        position: fixed !important;
        z-index: 300;
        top:49px;
        }
        .configuratorsteps {
            justify-content: center !important;
            margin-left: 24px;
        }
        #modelcanvas2{
            margin-left: 0px;
        }
        .header-text-left div{
            margin-left: 16px;
        }
        #resp-menu{
            display:flex !important;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255,255,255,0.1);
        }
        #responsivemenuitems{
            margin-top: 50px !important;
        }
        #resp-menu li:first-child{
            margin-left: 15px;
            height: 48px;
            display: flex;
        align-items: center;
        justify-content: center;
        }
        #resp-menu li:last-child{
            margin-right: 16px;
        }
    
        .HeaderSlim-wrapper{
            height: unset !important;
        }
        .Header-wrapper .sticky{
            height: unset !important;
        }
        .Searchresults-search{
            display: flex;
            justify-content: space-between;
        }
        .product-cardy-link{
            height: unset !important;
            width: unset !important;
            margin-left: 0px !important;
        }
        .Searchresults-results h2{
            text-align: left;
        }
        .product-cardy-link img{
            height: 60px !important;
            width: 60px !important;
        }
        #searchinput{
            width: 60%;
        }
        #searchLogo{
            width: unset !important;
        }
        .Searchresults-searchbar-buttons button:first-child{
            display: none !important ;
        }
        .Searchresults-searchbar-inner img{
            display: none;
        }
        .suggestmainpage{
            margin-top: -100px;
            top: 25px;
            padding-top:2px;
            margin-left: 0px !important;
            left: 0px !important;
          
        }
        .smalllanguagemenu{
            position: unset !important;
        }
        #searchmenu{
            justify-content: flex-start !important;
            width: 100% !important;
        }
        .Header-menu1{
            width: 100%;
        }
        .asearchlink{
            height: unset !important;
            margin-top: 32px !important;
            font-size: 14px !important;
            justify-content: flex-start !important;
        }
        .asearchlink span{
            margin-left: -12px !important;
        }
        .asearchlink span img{
            width: 20px !important;
            height: 20px !important;
            margin-top: 5px;
        }
        .UsedComponents-Cards{
            grid-template-columns: 1fr 1fr !important;
        }
        #searchtext{
            padding: 5px;
        }
        #searchmenu img{
            margin-left: 16px !important;
        }
        #mainmenu{
            display: none;
        }
        .HeaderMainpage-Submenu li{
            margin-bottom: 8px !important;
        }
        .Header-top{
            display: flex !important;
         grid-template-columns:    1px calc( 50% - 50px)  calc(50% - 50px)  50px;
        }
        .seperator{
            display: none !important;
        }
        .Header-menu1 ul li:nth-child(1) {
            padding-left: 5px;
        }
        .Header-menu1 ul li:nth-child(2) {
            padding-left: 5px;
        }
    
    
        .configuratorsteps {
            top: 5px !important;
            transform: translate(-55%) !important;
        }
        .Selects-configu {
            width: 100%;
            max-width: unset !important;
        }
        .header-text-left div p {
            margin-top: 40px;
        }
        .header-texts {
            margin-top: 0px;
        }
        .header-texts {
            grid-template-columns: 1fr !important;
        }
        .header-text-left h2{
            font-size: 48px !important;
            line-height: 56px;
        }
        .header-selection-menu div a svg{
            margin-right: 24px;
        }
        .header-selection-menu div div{
          
        }
        .header-selection-menu{
            height: 192px !important;
            
            margin-top: -130px !important;
        }
        .header-selection-menu {
            
            grid-template-columns: 1fr !important;
            width: calc(100% - 16px) !important;
            float: left !important;
        }
        .header-selection-menu div a{
            justify-content: space-between;
            padding-left: 16px;
            width: 100%;
            font-size: 16px !important;
        }
    
        .header-selection-menu p{
            margin-top: 16px !important;
            padding-left: 16px;
        }
        .header-selection-menu div div{
            width: 100%;
        }
        .seotopicblock-inner-text h2{
            font-size: 40px !important;
        }
        .seotopicblock-first-text{
            font-size: 20px !important;
        }
        .Searchresults-Products-Wrap{
            display: flex !important; 
            flex-wrap: wrap;
            position: relative;
        }
        .Searchresults-results{
            grid-template-columns: 1fr !important;
        }
        .docs-card{
            padding-left: 16px;
            padding-right: 16px;
        }
        .systemgrid-grid{
            grid-template-columns: 1fr 1fr 1fr !important;
        }
        .systemgrid-head-text p{
            max-width: 540px ;
        }
        .Searchresults-Products{
            max-width: 100%;
        }
        .Searchresults-search{
            padding: 8px 0px;
            margin-top: 2px;
        }
        .HeaderMainpage-text-left div{
            margin-top: 148px !important;
        }
    
        .header-text-projects div{
            margin-top: 196px !important;
        }
        
        .header-banner{
            height: 590px !important;
        }
        .SystemHeadline-text{
            padding-left: 16px;
            padding-right: 16px;
        }
        .lang-dev-wrap{
            grid-template-columns: 1fr 1fr 1fr !important;
        }
        .Contenttext-inner h2{
            font-size: 32px !important;
            word-break: break-all;
        }
        .Contenttext-wrap-head{
            padding-top: 64px !important;
            padding-bottom: 64px !important;
        }
    }
    
    @media (max-width:840px) {
        
        .service-block-card {
            display: flex !important; 
            max-height: unset !important;
            flex-direction: column !important;
        }
        .service-block-wrap {
            padding-top: 64px !important;
            padding-bottom: 64px !important;
        }
        .service-block-card-2 {
            margin-top: 0px !important;
        }
        .service-block-text div h3{
            margin-top: 52px;
            margin-bottom: 24px;
        }
        .service-block-card-2 div:first-child{
            order: 2;
        }
        .service-block-text button{
            margin-top: 32px;
            margin-bottom: 52px;
        }
        .ImageServiceBlock{
            display: flex;
            background-color: white;
            justify-content: center;
        }
    }
    
    
    
    @media (max-width:768px) {
        .footer-third-row-buttons button:last-child svg{
            margin-right:0px  !important;
        }
    
        .Configure-block-wrap{
           
            padding-bottom: 64px !important;
    
        }
        .product-card2{
            margin-left: 16px;
        }
        .Recentprojects-wrap,.servicecards2-wrap,.SwiperSlider-wrap,.SwiperSlider2-wrap{
            padding-bottom: 64px !important;
            padding-top: 64px !important;
        }
    
        .Systems-slider-top-text div:last-child{
            flex-direction: row !important;
            margin-top: 24px;
        }
        .overviewsystems{
            display: block !important;
            text-align: center !important;
            margin-top: 64px !important;
        }
        .NeedHelp-cards {
        grid-template-columns: 1fr !important;
        margin-left: 16px;
        }
        #specifics h2{
            word-break: break-all;
        }
        #proj-links-inner-spezfix p{
            width: 100%;
            height: 100% ;
        }
    
        #specifics{
            padding-left: 16px;
            padding-right: 16px;
        }
        .specificsrow{
            display: block !important;
        }
        .specificsrow p:last-child{
            margin-top: 8px;
        }
        .systemheader-text h2{
            font-size: 48px !important;
            line-height: 56px !important;
            font-weight: 200 !important;
        }
        .Contenttext-inner-inner{
            margin-top: 64px !important;
            margin-bottom: 64px !important;
        }
        .Contenttext-inner h2{
            margin-left: 16px;
        }
        #api-data{
            padding-left: 16px;
            padding-right: 16px;
        }
        .systemheader-text h2 h1{
            font-size: 64px !important ;
        }
    
        .howwework-cards{
            grid-template-columns: 1fr !important;
        }
        .Seotopics-texts h2{
            margin-bottom: 24px;
            font-size: 32px !important;
        }
        .systemgrid-grid{
            grid-template-columns: 1fr 1fr !important;
        }
    
        .jobcenter-jobcard{
            display: block !important;
            padding-left: 16px;
            padding-right: 16px;
        }
    
        .lang-dev-wrap{
            grid-template-columns: 1fr 1fr !important;
        }
        .Service-inner{
            padding-left: 16px;
            padding-right: 16px;
        }
        #jobcenter-list-headline{
            padding-left: 16px ;
            padding-right: 16px;
            font-size: 32px !important;
        }
        .jobcenterdetail-inner{
            padding-left: 16px;
            padding-right: 16px;
        }
        .UsedComponents-Headline h2{
            font-size: 48px !important;
        }
        .Seotopics-texts{
            padding-left: 16px;
            padding-right: 16px;
            grid-template-columns: 1fr !important;
            margin-bottom: 80px !important;
        }
        .jobcenter-header{
            margin-bottom: 64px !important;
            padding-left: 16px;
            padding-right: 16px;
            width: 100%;
        }
        
        .jobcenter-header h1{
            font-size: 48px !important;
        }
        .jobcenter-header-subheader{
            display: block !important;
        }
        .systemgrid-inner{
            margin-top: 64px !important;
            margin-bottom: 64px !important;
        }
        .jobcenter-header-subheader div{
            margin-top: 16px;
        }
        .systemgrid-inner{
            margin-top: 64px !important;
            margin-bottom: 64px !important;
        }
        .SystemHeadline-text{
            padding-top: 60px !important;
            padding-bottom: 80px !important;
        }
        .SystemHeadline-text h2{
            font-size: 48px !important;
            line-height: 56px !important;
        } 
        .seotopicblock-inner-text{
            grid-template-columns: 1fr !important;
            padding-left: 16px;
            padding-right: 16px;
        }
        .seotopicblock-inner-text h2{
            margin-bottom: 24px;
        }
        .Seotopic-inner{
            margin-top: 64px !important;
            margin-bottom: 64px !important;
    
        }
        .projectblock-head{
            flex-direction: column;
            gap: 16px;
            align-items: unset !important; 
            margin-left: 32px !important;
        }
        .projectblock-filter{
            padding: 16px;
            margin-bottom: 24px;
        }
        .Catalogue-blöcke{
           flex-direction: column;
           padding-left: 16px;
           padding-right: 16px;
        }
        .Catalogue-wrap{
            padding-top: 64px !important;
            padding-bottom: 64px !important;
        }
        .Catalogue-Benefits-wrap div{
            margin-left: 0 !important;
            padding: 8px;
        }
        .Catalogue-image img{
            max-width: 100%;
            height: 100%;
        }
        .Catalogue-image{
            margin-top: 64px;
        }
        .systemgrid-head .projectblock-filter{
            padding: 0px;
        }
        .Registerform-inner{
            padding-left: 16px;
            padding-right: 16px;
        }
        .Registerform-inputs input,.Registerform-inputs select{
            max-width: unset  !important;
        }
        .Registerform-inner-middle-headline h3{
            margin-top: 0px !important;
            font-size: 32px !important;
        }
        .Registerform-inner .jobcenter-header{
            padding: 0px !important;
        }
        .Registerform-inputs{
            grid-template-columns: 1fr !important;
        }
        .header-text-left div h2{
            font-size: 64px;
        }
        .Recentprojects-projects{
            grid-template-columns: 1fr !important;
        }
        .Recentprojects-project-card{
            padding-left: 16px;
            padding-right: 16px;
        }
        .faq-question-warp div:first-child p{
            font-size: 18px !important;
            
        }
    
        .ServiceFaqButtons{
            flex-wrap: wrap;
            margin-bottom: 32px !important;
        }
        .Service-inner h2{
            font-size: 48px !important;
         
        }
    
        .TrainingsOverview-Card{
            display: flex !important;
        }
        .Service-menu-inner{
            padding-left: 16px;
            padding-right: 16px;
            display: block !important;
        }
        .Service-menu-inner div:last-child{
            margin-top: 48px;
        }
        .faq-question-warp div:first-child button{
            width: 32px !important;
            height: 32px !important;
    
            margin-right: 16px !important;
        }
        .Recentprojects-head h2{
            font-size: 40px !important;
            line-height: 48px !important;
        }
        .Recentprojects-head{
            padding-left: 16px;
            padding-right: 16px;
        }
       
        .suggestoutput{
            position: fixed !important;
            margin-top: 22px;
        }
        .configuratorsteps {
            width: 600px !important;
        }
        .Configure-block-cards{
            padding-left: 16px;
            padding-right: 16px;
            grid-template-columns: 1fr !important;
        }
        .Configure-block-card{
            padding-left: 24px;
            padding-right: 24px;
        
        }
     
        .Configure-block-head h2{
            padding-left: 24px !important;
            padding-right: 24px !important	;
            font-size: 40px !important;
        }
    
        .service-block-header h2{
            text-align: center;
        }
        .howwework2-cards{
            grid-template-columns: 1fr !important;
        }
        .NeedHelp-wrap{
            padding-top: 64px !important;
           padding-bottom: 64px !important;
        }
        .howwework2-cards .howwework2-card:nth-child(1){
            padding-right: 0px !important;
            border-right: none !important;
            padding: 16px !important; 
            padding-bottom: 64px !important;
        }
        .howwework2-cards .howwework2-card:nth-child(2){
            padding-left: 16px !important; 
            padding-right: 16px !important; 
        }
        .howwework2-wrap{
            padding-top: 64px    !important;
            padding-bottom: 64px !important;
        }
        #howwework2-button{
            display:none !important; 
        }
        #howwework2-button-resp{
            display: block !important;
        }
        .howwework2-header div{
            display: block !important;
        }
        .howwework2-cards .howwework2-card:nth-child(3){
            padding-left: 0px !important;
            border-left: none !important;
            padding: 16px !important; 
            padding-top: 64px !important;
        }
        .howwework2-header div p{
            flex-basis:80% ;
        }
        .howwework2-header{
            padding-left: 16px;
            padding-right: 16px;
        }
        .Systems-slider-top-inner{
       
        }
        .servicecards-cards ,  .servicecards2-cards{
            grid-template-columns: 1fr 1fr !important;
        }
        .servicecards2-header p{
            max-width: 100% !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
            text-align: center;
        }
        .servicecards2-header h2{
            padding-left: 16px;
            padding-right: 16px;
            text-align: center;
        }
        .servicecards-inner{
            margin-bottom: 64px;
        }
        .servicecards-wrap{
            height: unset !important;
        }
        .thecompareitems div{
            width: 100% !important;
        }
        
        .Systems-slider-top-text div:last-child{
            flex-direction: column;
           
            justify-content: flex-start !important;
        }
        .Systems-slider-top-text div:last-child button:first-child{
            margin-bottom: 8px;
        }
    
    
        .footer-first-row, .footer-second-row,.footer-third-row,.footer-last-row{
            display: none !important;
        }
        .footer-mobile-design{
            display: block !important;
        }
    
        .footer-third-row-icons{
            margin-top: 24px;
            margin-bottom: 24px;
            justify-content: center ;
        }
        .service-block-text div {
            width: 100% !important	;
            padding-left: 48px;
            padding-right: 48px;
        }
    
        .service-block-card img {
            padding-left: 16px;
            padding-right: 16px;
            max-width: 100%;
            height: unset;
        }
        .footer-mobile-design{
            display: block !important;
        }
        .projectblock-outer-wrap{
            margin-top: 64px !important;
            padding-top: 128px !important;
            padding-bottom: 64px !important;
        }
        .Systems-slider-top-inner{
            padding: 0px 16px !important;
        }
        .SystemDetailBlock-BildLinks , .SystemDetailBlock-BildRechts{
            flex-direction: column  !important;
        }
        .SystemDetailBlock-BildRechts div{
            order: 2;
        }
        .SystemDetailBlock-Bannerbild{
            display: none;
        }
        .SystemDetailBlock-Card-wrap h3{
            margin-top: 24px;
        }
        .FormFileBlock-Textinputs-inner{
            display: block;
            padding-left: 16px;
            padding-right: 16px;
        }
        .configuratorsteps-btnrow{
            position: absolute;
            left: 16px ;
            width: 536px !important;
        }
        .configuratorsteps{
            margin-left: 0px !important;
        }
        .Systems-slider-top-wrap a{
            display: none !important;
        }
        .ResponsiveButtonSystems{
            display: flex !important;
        }
        .draganddropslider-wrap-systems{
            margin-left: 16px;
        }
        .Systems-slider-top-text{
            grid-template-columns: 1fr !important;
        }
        .overlay{
            top: 32% !important;
        }
        .About-card{
            grid-template-columns: 1fr !important;
        }
    
        .About-card div:first-child{
            margin-bottom: 16px !important;
        }
        .AboutBlock-cards,.AboutBlock-wrap, .AboutBlock-Head-Wrap{
            margin-top: 64px !important;
            margin-bottom: 64px !important;
        }
        .AboutBlock-Head-Wrap{
            margin-bottom: 80px !important;
        }
        .AboutBlock-wrap{
            margin-bottom: 24px !important;
        }
        .AboutBlock-scroll-buttons div ul{
            flex-wrap: wrap !important;
        }
        .AboutBlock-scroll-buttons{
            height: unset !important;
            padding-left: 16px;
            padding-right:16px ;
        }
        .AboutBlock-cards h3{
            font-size: 32px !important;
        }
        .AboutBlock-careerbutton{
            display: none !important;
        }
        .responsiveAboutBtn{
            display: block !important;
            width: 120px;
            text-align: center;
            margin-top: 24px;
        }
        .AboutBlock-cards{
            margin-bottom: 64px !important;
        }
        .AboutBlock-cards,.AboutBlock-Head{
            padding-left: 16px !important;
            padding-right: 16px !important;
        }
        .AboutBlock-Head h2{
            font-size: 48px !important;
        }
        .AboutBlock-cards{
            margin-top: 0px !important;
        }
        .About-card{
            margin-bottom: 64px !important;
        }
        .ContactpageLineHq{
            display: block !important;
        }
        .Contact-inner div:nth-child(3){
            margin-top: 0px !important;
        }
        .visibleQR {
            display: block !important;
            margin-top: -0px !important;
        }
        .Contact-inner{
            padding-left: 16px;
            padding-right: 16px;
        }
        .Contact-Header{
            display: block;
        }
        .Contact-Buttons{
            flex-wrap: wrap;
            margin-top: 32px;
        }
        .SystemDownloads-grid{
            display: block !important;
        }
        .SystemDownloads-wrap{
            padding-top: 64px;
            padding-bottom: 64px;
            padding-left: 16px;
            padding-right: 16px;
        }
        .SiteScrollMenu-inner{
            padding-left: 16px;
            padding-right: 16px;
        }
        .systemheader-text h2 h3 {
            font-size: 48px !important;
        }
        .systemheader-text{
            margin-top: 185px !important;
        }
        #HeaderSystemDetail-buynowbutton{
            display: flex !important;
            width: 100%;
            font-size: 13px;
        }
        .SiteScrollMenu-inner div:last-child{
            display: none !important;
        }
        .SystemAccessoriesGrid-Headline{
            flex-direction: column;
            align-items: center;
            gap: 24px;
        }
        .Searchresults-Products-Wrap{
            display: grid !important;
            grid-template-columns: 1fr 1fr;
        }
        .SystemAccessoriesGrid-wrap{
            margin-top: 64px !important;
            margin-bottom: 64px !important;
        }
        .projectdetail-header{
            display:  grid !important;
            grid-template-columns: 1fr;
            gap: 24px;
            margin-bottom: 48px  !important;
            margin-top: 64px !important;
        }
        .projectdetail-wrapper{
            padding-left: 16px;
            padding-right: 16px;
        }
        .Contact-Header{
            display:  grid !important;
            grid-template-columns: 1fr;
            gap: 12px;
        }
        .NewsletterNew-wrap{
            padding-top: 64px !important;
            padding-bottom: 64px !important;
        }
       
    }
    @media (max-width:640px) {
        .header-text-left div h2{
            font-size: 40px;
        }
        .configuratorsteps button{
            
        }
    
        .configuratorsteps .rotationbox button{
            display: block;
        }
        .rotationbox{
            margin-left: 0px;
        }
        .configuratorsteps{
            width: 100% !important;
            background-color: transparent !important;
        }
        .videos-card button{
            display: block !important;
        }
        .videos-card button img{
            width:100% !important;
    
        }
    }
    
    @media (max-width:540px) {
        .systemgrid-grid{
            grid-template-columns: 1fr !important;
        }
        .SiteScrollMenu-inner div:last-child {
            padding-top: 24px !important;
        }
        .NewsletterNew-left{
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    
    @media (max-width:480px) {
        .NeedHelp-Head{
            display: block !important;
        }
        .HeaderMainpage-text-left div button, #brand-line{
            display: none;
        }
    
        .NeedHelp-Head img{
            width: 200px !important;
            height: 200px !important;
        }
        .header-text-left div{
            margin-left: 10px;
        }
        .HeaderMainpage-text-left div{
            margin-left: 16px;
            margin-right: 16px;
        }
        .servicecards-cards ,  .servicecards2-cards{
            padding-left: 16px;
            padding-right: 16px;
            grid-template-columns: 1fr !important;
        }
        .servicecards-header {
            margin-top: 64px !important;
        }
        .servicecards-header p{
            margin-left: 16px;
            margin-right: 16px;
        }
        .UsedComponents-Cards{
        grid-template-columns: 1fr !important;
        }
        .Configure-block-card h3 {
        font-size: 18px !important;
        max-width: 265px !important;
        }
        .Configure-block-card-btnrow{
            height: 74px !important;
        }
        .Configure-block-card{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .TrainingsOverview-Card{
            padding-top: 48px  !important;
            text-align: center;
            display:  block !important;
            padding-bottom: 48px !important;
          }
          .TrainingsOverview-Card .TrainingsOverview-Linkside{
              margin-top: 24px;
          }
          .TrainingsOverview-contact-inner{
            padding-left: 16px;
            padding-right: 16px;
          }
         .TrainingsOverview-inner-headline{
            margin-top: 64px !important;
            margin-bottom: 0px !important;
            text-align: center !important;
          }
    }