

.Header-wrap{
    display:flex;
    justify-content: center;  
    height: 150px;
}
.Header{
    width: 1170px;
}
.Header a{
    text-decoration: none;
    color:black
}
.Header-top{
    display:flex;
    justify-content: space-between;
}
.Header-top div ul{
    display: flex;

}
.Header-top div ul li{

    list-style: none;
}
.Header-top div .Header-promise img{
    width: 16px;
    height: 16px;
   margin-right: 10px;
   margin-top:5px;
}
.Header-user-nav li {
    border-right:1px solid #ccc;
    padding-left:5px;
    padding-right:5px;
    display:flex;
    justify-content: center;
    text-align: center;
}
/*header-mid*/

.Header-mid{
    display:flex;
    align-items: center;
}
.Header-mid-search{
    display: flex;
    flex-basis: calc(100%);
    justify-content: center;
    position: relative;
}
.Header-mid-search input{
    width: 55%;
    height: 42px;
    border-radius: 42px;
    padding-left: 42px;
    border: 1px solid #005094;

}
.search-suggest{
    position: absolute;
    margin-top: 50px;
    z-index: 100;
    margin-left: -46px;
    background-color: #fff;
    width: 55%;
    border: 1px solid transparent;
    

}
/*Header-Bottom*/
.Header-bottom{
    background-color: #f7f7f7;
    height: 52px;
    display:flex;
    align-items: center;
}

.Header-bottom ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    
}
.Header-bottom ul li a{
    color: #333
}
.Header-bottom ul li{
    margin: 10px;
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;


}
.loginpopup{
    display:none
}
.loginpopup button{
    background-color: #005094;
    padding: 5px; color:white;
    margin-top: 10px;

}
.loginpopup input{
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 5px;
    margin-left: 10px;
}
#logintab:hover .loginpopup{
    padding: 7px;
    display: block;
    position: absolute;
    top: 80px;
    background-color: #ccc;
    color:black;
    display: flex;
    flex-direction: column;
}
.langtogglemenu{
    display: flex;
    flex-direction: column;
    position:absolute;
    z-index: 9999;
    padding: 8px;
    background-color: #fff;
    top: 0px;
    left:0px;
    width: 100%;
    position: fixed;
    height: 100%;
    float:left;
    overflow-y: scroll;
    animation-name: comein;
    animation-duration: 0.5s;

}
.CloseFooterButton{
    top: 90px
}
@keyframes comein {
    from {left:-1500px; width: 0px;}
    to {left:0px; width: 100%;}
  }


@keyframes comein2 {
    from {left:2500px; width: 0px;}
    to {left:0px; width: 100%;}
  }

  .langtogglemenu a{
    display: flex;
    margin: 2px;
    padding:4px;
    text-align: left;
    background-color: transparent;
    border:none;
  }
.langtogglemenu button{
    display: flex;
    margin: 2px;
    padding:4px;
    text-align: left;
    background-color: transparent;
    border:none;
}
.langtogglemenu button img{
    margin-right: 25px;
}
.langtogglemenu button:hover{
    cursor: pointer;
    opacity: 0.5;
}
.lang-dev-wrap {
text-align: left;
}

.lang-dev-wrap h3{
    padding-top: 50px;
    padding-bottom: 30px;
    font-size: 22px;
    
}
#closelangsel{
    font-weight: 900;
    font-size: 22px;
    position: absolute;
    right: 20px;
    padding-left:12px;
    padding-right: 12px;
    border:2px solid black;
    border-radius: 50%;
}
.lang-card img{
    width: 32px;
    height: 20px;
    margin-right: 8px;

}
.lang-dev-wrap a:hover{
    opacity: 0.9;
    transition: 0.1s;
    transform: scale(1.1);
}
.lang-dev-wrap a{
    cursor: pointer;
    text-decoration: none;
    color: #001020;
    font-weight: 600;
}
.search-suggest{
    display:none;
}
.lang-card h4{
    font-size: 16px;
}
.lang-card{
    display:flex;
}
.lang-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px
}
.lang-button-wrapper{
    max-width: 1170px;
    margin: 0 auto;
}
#landingpage{
    position: relative;
    background-color: #fff;
}
.Landingpage-Text{
    width: 80%;
}
.Landingpage-block{
    z-index: 100;
    margin-top: -100px;
    background-color: #fff;
    padding: 30px; 
}
.Langselect{
    border: none;
    background-color: transparent;
    display:flex
}
.Langselect span{
    font-size: 18px;
    margin-top:7px;
    margin-left:7px
}
.Langselect:hover{
    opacity: 0.8;
    transform: scale(1.1);
    transition: 0.4s;
    cursor: pointer;
}

.Header-usermenu ul{
    display:flex;
}

.Header-usermenu ul li{
    margin: 10px;
    border:none;
}
.Header-logo{
    width: 200px;
}

#search{
    background-color: #005094;
    width: 42px;
    height: 42px;
    border-radius: 0% 50% 50% 0%;
    margin-left: -42px;
    border: 0px;
}
#search:hover{
    transform: scale(1.01);
    transition: 0.2s;
    opacity: 0.9;
    cursor: pointer;
}

#search img{
    
    width: 50%;
    height: 50%;
}

.Search-Products a, .Search-Documents a{
    display: flex;
    align-items: center;
    padding: 5px;
  
    border-radius: 15px;
}
#prods h2, #docs h2{
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  
    font-weight: 900;

}

#prods h2, #docs h2{


    padding: 15px;
    background-color: #eee;
    font-size:20px;
    color: #333;
    box-shadow: -3px 5px 11px #ccc;
   
    
}

#prods,#docs{
    overflow:hidden;
}

.Search-Product-list li:first-child,.Search-Docs-list li:first-child {

    margin-top: 5px;
   
}

.Search-Product-list li:last-child{

    margin-bottom: 10px;
}
.Search-Product-list, .Search-Docs-list{
    display: flex;

    flex-direction: column;
    align-items: center;
}

.Search-Product-list li, .Search-Docs-list li{
   width: 100%;
    list-style: none;
}

.Search-Product-list li:hover, .Search-Docs-list li:hover{
    opacity: 0.9;
    
    transition: 0.1s;
    font-weight: 600;
}

.Search-Products a img,.Search-Documents a img{
    margin-right: 30px;
}

.bordery {
    border: 1px solid #ccc;
}


.hideMe {
    -moz-animation: cssAnimation 0s ease-in 0.3s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 0.3s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 0.3s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 0.3s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    
}
@keyframes cssAnimation {
    from{
        
        width: 100%;
        height: 100%
    }
    to {
    
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}

.diffcounts{
 display: flex;
 justify-content: center;
}

.diffcounts-inner{
    max-width: 870px;
    display: flex;
    flex-wrap: wrap;
}

.diffcounts-inner button{
   
    border-right: 1px solid #ccc;
}
.allcountries{
    display: flex;
    width: 100%;
    justify-content: center;
}

.allcountries-inner{
    width: 100%;
    max-width: 870px;

}
.allcountries-inner button{

    padding: 16px 28px 16px 28px;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 7px #ccc;
    margin-top: 60px;
    font-size: 22px;
    font-weight: 600;
}
.allcountries-inner button p{
    transform: rotate(270deg);
    margin-left: 20px;
}
.viewer{
    display: block ;
    animation-name: comein2;
    animation-duration: 0.5s;
}
@keyframes comein2 {
    from {opacity: 0;}
    to {opacity: 1;}
  }
.hider{
    display: none ;
}
.sticky {
    position: fixed;
    top: 0;
    z-index: 100000;
    background-color: white;
    width: 100%;
    height: 106px;
    border-bottom: 1px solid #eee !important;
  
  }
.sticky .Header-menu1,.sticky .Header-menu2{
  
}

.sticky .Header-mid{
    display: none;
}
.sticky .Header-menu2 ul li a{
    color:black !important;
}
.sticky .Header-menu1 ul li a{
    color:black;
}
.Header_Level_1{
    position: relative;
}
#sortimentlink{
    border: none;
}
.Header_Level_1:hover a,.Header_Level_1:hover #sortimentlink{
    border-bottom: 5px solid #005094;

   
}

.Header_Level_1:hover{
    
}


.Header_Level_1:hover .Header_Level_2 a{
    border-bottom: none;
}
.Header_Level_1:hover #configlink,.Header_Level_1:hover #salelink{
    border:none;
}

.Header_Level_1:hover .Header_Level_2{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 380px;
    background-color: #eee;
    z-index: 30000;
    position: fixed;
       
    animation-name: openup;
    animation-duration: 0.3s;
    top:144px;
    left: 0px;

}

#sortimentlink{
    margin-top: -2px;
}

.Header_Level_2{
    display: none;
    position: fixed;
    width: 100%;
    height: 380px;
    background-color: #ccc;
    z-index: 30000;
   
    top:144px;
    left: 0px;
 
}
.Header_Level_2-inner:hover .Header_Level_2-inner{
    display: flex;
}
.Header_Level_2-inner{
    max-width: 1170px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
}
.Level_2-spalte {
    padding-top: 30px;
}
.Level_2-spalte ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.Level_2-spalte h3{
    margin-bottom: 15px;

}
.Level_2-spalte-img{
    padding-top: 0px;
}
#systems-header{
    animation-name: comein2;
    animation-duration: 0.3s;
}
#systems-header img{
    height: 200px;
}

@keyframes openup {
    from {opacity: 0; height:4px;}
    to {opacity:1;height: 380px}
  }
