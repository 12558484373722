

*{
  box-sizing: border-box;
}

*{
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif;
 
}
body{
  height: 100%;
  background-color: #f2f3f4;

}
.Backtohome-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Backtohome-link svg{
  transform: rotate(180deg);
  margin-right: 16px;
}
#root{
  overflow-x: hidden;
}
/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,700&display=swap');*/
:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
:root { font-family: 'Inter var', sans-serif; }
}

a{
  text-decoration: none;
}
button{
  cursor: pointer;
}

.Header-wrapper{
  background-color: white;
}